import React from 'react';

const HomeBanner = () => {
  return (
    <div className="bg-white rounded-lg p-11 2xl:py-20 2xl:px-48 ">
      <h2 className="text-black text-2xl font-bold text-center mb-10 sm:mb-14">
        As melhores empresas estão no Vendoor:
      </h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 sm:gap-20 ">
        <p className="text-[#1e1e1e] text-sm leading-4">
          No Vendoor, as melhores empresas dos setores de varejo, comercial,
          administrativo, saúde e tecnologia escolhem nossa plataforma para
          contratar talentos. Oferecemos uma vasta gama de empregos que vão
          desde posições iniciais até cargos de alta gestão.
        </p>
        <p className="text-[#1e1e1e] text-sm leading-4">
          Explore oportunidades que complementam suas habilidades e impulsionam
          seu desenvolvimento profissional, em empresas que são líderes de
          mercado. Descubra hoje mesmo os melhores empregos no Vendoor e avance
          na sua carreira.
        </p>
      </div>
    </div>
  );
};

export default HomeBanner;
