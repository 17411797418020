import React from 'react';
import * as classes from './workfield-card.module.scss';
import { StaticImage } from 'gatsby-plugin-image';
import { Position } from '../../sdk/entities/Position';

interface WorkfieldCardProps {
  variant: 'Vendas' | 'Administrativo' | 'Serviços Gerais';
  positions: Position[];
  slug: 'comercial-e-vendas' | 'administracao' | 'servicos-gerais';
}

const WorkfieldCard = (props: WorkfieldCardProps) => {
  const { variant, positions, slug } = props;

  return (
    <div className="bg-white p-5 sm:p-10 sm:pt-5 text-[#3D3D3D] font-semibold rounded-lg min-w-fit flex flex-col">
      {variant === 'Serviços Gerais' ? (
        <div className={classes.imgWrapperSmall}>
          <StaticImage
            src={`../../images/servicos-gerais.png`}
            alt="Vagas de emprego na área de serviços gerais"
            objectFit="cover"
            width={365}
            formats={['webp']}
          />
        </div>
      ) : variant === 'Administrativo' ? (
        <div className={classes.imgWrapper}>
          <StaticImage
            src={`../../images/administrativo.png`}
            alt="Vagas de emprego na área administrativa"
            objectFit="cover"
            width={365}
            formats={['webp']}
          />
        </div>
      ) : (
        <div className={classes.imgWrapper}>
          <StaticImage
            src={`../../images/vendas.png`}
            alt="Vagas de emprego na área de vendas"
            objectFit="cover"
            width={365}
            formats={['webp']}
          />
        </div>
      )}

      <div className="flex flex-col grow ">
        <h3 className="text-2xl mt-7 sm:mt-3">{variant}</h3>
        <h4 className="mb-3 text-sm ">Cargos disponíveis:</h4>
        <div className="flex gap-6 flex-col justify-between lg:flex-row md:gap-3 grow bg-white self-end">
          <div className={classes.tagsWrapper}>
            {positions.slice(0, 4).map(position => (
              <div key={position.id}>
                <h5 className={classes.tag}>{position.name}</h5>
              </div>
            ))}
            <div className={classes.morePositions}>
              <h5
                className={classes.tag}
              >{`Mais ${positions.length - 4} cargos disponíveis`}</h5>
            </div>
          </div>
          <a
            href={`/${slug}/`}
            className="h-[40px] p-3 px-4 bg-[#66778A] rounded items-center justify-center inline-flex text-white text-xs font-semibold shrink-0 self-start lg:self-end shadow-[0_0_25px_0px_rgba(113,128,150,0.5)] justify-end"
          >
            Ver vagas
          </a>
        </div>
      </div>
    </div>
  );
};

export default WorkfieldCard;
