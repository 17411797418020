import React from 'react';
import { faq } from './faq-data';
import * as classes from './faq.module.scss';

const Faq = () => {
  return (
    <div>
      <h2 className="text-black text-2xl font-bold text-center mb-14">
        Perguntas Frequentes para Candidatos do Vendoor
      </h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 sm:gap-9">
        <div>
          {faq.slice(0, 5).map((faq, index) => (
            <div className={classes.faq} key={Math.random()}>
              <p className={classes.question}>
                {index + 1}. {faq.question}
              </p>
              <p>{faq.answer}</p>
            </div>
          ))}
        </div>
        <div>
          {faq.slice(5).map((faq, index) => (
            <div className={classes.faq} key={Math.random()}>
              <p className={classes.question}>
                {index + 6}. {faq.question}
              </p>
              <p>{faq.answer}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Faq;
