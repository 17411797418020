export const faq = [
  {
    question: 'Como criar uma conta?',
    answer:
      'Para criar uma conta no Vendoor, visite nosso site e clique em "Cadastre-se". Preencha o formulário com suas informações pessoais, incluindo seu endereço de e-mail e uma senha segura. Após enviar o formulário, você receberá uma mensagem no WhatsApp para ativar sua conta.',
  },
  {
    question: 'Como me candidatar?',
    answer:
      'Após criar sua conta e fazer login, navegue até a seção "Vagas" para ver as oportunidades disponíveis. Escolha a vaga que se encaixa no seu perfil e clique em "Candidatar-se". Siga as instruções para enviar seu currículo e demais informações necessárias.',
  },
  {
    question: 'Eu preciso pagar para me candidatar?',
    answer:
      'Não, o Vendoor não cobra dos candidatos para se candidatarem às vagas. Nosso objetivo é facilitar a conexão entre candidatos e empresas de maneira eficiente e acessível.',
  },
  {
    question: 'Como funciona o processo de seleção no Vendoor?',
    answer:
      'O processo de seleção varia de acordo com a empresa e a vaga. Normalmente, inclui a análise do seu perfil e documentos submetidos, seguida por entrevistas ou testes específicos da área. Os candidatos selecionados serão contatados diretamente pela empresa para os próximos passos.',
  },
  {
    question: 'Quanto tempo leva o processo de seleção?',
    answer:
      'O tempo do processo de seleção pode variar, dependendo da empresa e do número de candidaturas. Recomendamos acompanhar o status da sua candidatura na plataforma através do menu "Minhas Candidaturas" para atualizações.',
  },
  {
    question:
      'Quais são os requisitos para se candidatar a uma vaga no Vendoor?',
    answer:
      'Os requisitos específicos para cada vaga estão listados na descrição da mesma. Geralmente, incluem qualificações educacionais, experiência profissional e habilidades técnicas.',
  },
  {
    question: 'Como posso acompanhar o status da minha candidatura?',
    answer:
      'Você pode verificar o status da sua candidatura acessando seu perfil no Vendoor e navegando até a seção "Minhas Candidaturas". Lá, você encontrará informações atualizadas sobre cada processo que você está participando.',
  },
  {
    question:
      'Encontrei um problema técnico com minha conta. Como posso resolver?',
    answer:
      'Caso encontre problemas técnicos, você pode entrar em contato com nosso suporte técnico através do menu "Suporte". Por favor, descreva o problema detalhadamente para que possamos ajudá-lo da melhor maneira possível.',
  },
  {
    question:
      'Quais são os termos e condições que preciso conhecer antes de me candidatar?',
    answer:
      'Recomendamos que todos os candidatos leiam atentamente os termos e condições disponíveis em nosso site. Estes termos incluem informações importantes sobre os direitos e responsabilidades dos usuários, bem como nossa política de privacidade.',
  },
  {
    question:
      'Como posso entrar em contato com o suporte se tiver perguntas ou precisar de ajuda?',
    answer:
      'Para perguntas ou assistência, você pode contatar nosso suporte ao cliente enviando uma mensagem através do menu "Suporte".',
  },
];
