import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

const ProductInfo = () => {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 gap-12 sm:gap-20 items-center ">
      <div>
        <StaticImage
          src="../../images/vaga-de-emprego-perfeita.png"
          alt="Não importa a sua experiência, a Vendoor tem uma vaga perfeita para você."
        />
      </div>
      <div>
        <h2 className="text-black text-2xl font-bold mb-3 max-w-lg">
          Não importa a sua experiência, temos uma vaga para você!
        </h2>
        <p className="text-[#1e1e1e] text-sm max-w-md ">
          No Vendoor, oferecemos vagas para todos os níveis de carreira, desde
          seu primeiro emprego até posições de assistente, analista,
          coordenador, gerente e mais. Independentemente de sua experiência ou
          estágio profissional, você encontrará oportunidades que se encaixam
          perfeitamente às suas habilidades e aspirações.
        </p>
      </div>
    </div>
  );
};

export default ProductInfo;
