import React, { useRef, useState } from 'react';
import WorkfieldCard from '../workfield-card/workfield-card';
import { FiArrowRight } from '@react-icons/all-files/fi/FiArrowRight';
import { FiArrowLeft } from '@react-icons/all-files/fi/FiArrowLeft';
import * as classes from './popular-workfields.module.scss';
import { Position } from '../../sdk/entities/Position';
import { graphql, useStaticQuery } from 'gatsby';

const PopularWorkfields = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [direction, setDirection] = useState('right');

  const positions: {
    salesPositions: {
      nodes: Position[];
    };
    administrativePositions: {
      nodes: Position[];
    };
    generalServicesPositions: {
      nodes: Position[];
    };
  } = useStaticQuery(graphql`
    query {
      salesPositions: allPosition(
        sort: { name: ASC }
        filter: { workField: { slug: { eq: "comercial-e-vendas" } } }
      ) {
        nodes {
          id
          name
        }
      }
      administrativePositions: allPosition(
        sort: { name: ASC }
        filter: { workField: { slug: { eq: "administracao" } } }
      ) {
        nodes {
          id
          name
        }
      }
      generalServicesPositions: allPosition(
        sort: { name: ASC }
        filter: { workField: { slug: { eq: "servicos-gerais" } } }
      ) {
        nodes {
          id
          name
        }
      }
    }
  `);

  const handleScroll = (direction: string) => {
    if (!containerRef.current) {
      return;
    }
    const scrollWidth = containerRef.current?.scrollWidth;
    const scrollLeft = containerRef.current?.scrollLeft;
    const clientWidth = containerRef.current?.clientWidth;

    if (scrollWidth === scrollLeft + clientWidth) {
      setDirection('left');
    }

    if (scrollLeft === 0) {
      setDirection('right');
    }

    if (direction === 'right') {
      containerRef.current.scrollLeft += 250;
      return;
    }

    containerRef.current.scrollLeft -= 250;
  };

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 gap-6  items-center shadow-[15_25_15px_0px_rgba(113,128,150,0.1)]">
      <div>
        <h2 className="text-black text-2xl font-bold mb-5">
          Áreas Profissionais Populares
        </h2>
        <p className="text-[#1e1e1e] text-sm leading-4 max-w-lg">
          Explore no Vendoor as áreas profissionais mais procuradas, desde seu
          primeiro emprego até posições sêniores. Nossa plataforma conecta você
          a oportunidades em setores como varejo, comercial, administrativo,
          saúde, finanças e outras, atendendo a todos os níveis de experiência.
        </p>
        <a
          href="/#page-links"
          className="hidden h-[40px] p-3 px-4 bg-black rounded-full items-center justify-center sm:inline-flex text-white text-xs font-semibold shadow-[0_0_25px_0px_rgba(113,128,150,0.5)] mt-7"
        >
          Ver lista completa
        </a>
      </div>
      <div className={classes.wrapper}>
        <div ref={containerRef} className={classes.container} tabIndex={0}>
          <WorkfieldCard
            key="vendas"
            variant="Vendas"
            positions={positions.salesPositions.nodes}
            slug="comercial-e-vendas"
          />
          <WorkfieldCard
            key="administrativo"
            variant="Administrativo"
            positions={positions.administrativePositions.nodes}
            slug="administracao"
          />
          <WorkfieldCard
            key="servicos gerais"
            variant="Serviços Gerais"
            positions={positions.generalServicesPositions.nodes}
            slug="servicos-gerais"
          />
        </div>
        <button
          className="bg-[#E1F317] absolute rounded-full p-2 top-[50%] inline-flex right-2 lg:right-0 -translate-y-1/2 shadow-[0_0_25px_0px_rgba(113,128,150,0.5)] h-[32px] w-[32px]"
          onClick={() => handleScroll(direction)}
          aria-label="Próxima área"
        >
          {direction === 'right' ? <FiArrowRight /> : <FiArrowLeft />}
        </button>
      </div>
      <a
        href="/#page-links"
        className="sm:hidden h-[40px] p-3 px-4 bg-black rounded-full items-center justify-center inline-flex text-white text-xs font-semibold shadow-[0_0_25px_0px_rgba(113,128,150,0.5)] w-fit m-auto"
      >
        Ver lista completa
      </a>
    </div>
  );
};

export default PopularWorkfields;
